import * as React from "react"
import Svg, { Path } from "react-native-svg"

function TopTriangle(props) {
    return (
        <Svg
            width={725}
            height={97}
            viewBox="0 0 725 97"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M12.343 95.25L362.5 1.553 712.657 95.25H12.343z"
                stroke="#fff"
                strokeWidth={3}
            />
        </Svg>
    )
}

export default TopTriangle;
