import * as React from "react"
import Svg, { Path } from "react-native-svg"
import { Image, StyleSheet, View } from "react-native-web";

function ConfirmScreen() {
    return (
        <View style={styles.root}>
            <Image source={require('../assets/complete.png')}
                style={styles.completeImage}
                resizeMode='contain' />
        </View>
    )
}
const styles = StyleSheet.create(
    {
        root: {
            backgroundColor: 'white',
            width: window.innerWidth,
            height: window.innerHeight,
        },
        completeImage: {
            width: "100%",
            height: '100%',
            resizeMode: 'cover',
        }
    });

export default ConfirmScreen;
