import { View, Text, StyleSheet, ImageBackground, Button, Image } from 'react-native';
import { isMobile } from '../helpers/deviceUtils';


function PrivacyPage({ navigation }) {

    return (
        <View style={styles.root}>
            <Text style={styles.header}>{`Privacy Policy`}</Text>
            <Text style={styles.subHeader}>{`Effective as of August 1, 2023`}</Text>
            <Text style={styles.description}>{`MyBetterBuilder.com (""MyBetterBuilder.com"", ""we"", ""our"" or ""us"") is committed to protecting the privacy and security of your personal information. This Privacy Policy explains how we collect, use, and disclose information when you use our website(s) and app(s) accessed through Internet-connected computers, televisions, or mobile devices (e.g., mobile phones, tablets), or otherwise (collectively, the ""Services"").`}</Text>
            <Text style={styles.title}>{`Information We Collect`}</Text>
            <Text style={styles.description}>{`a. Information You Provide: When you register for an account, we collect certain information, such as your email address. You may also provide additional information voluntarily.`}</Text>
            <Text style={styles.description}>{`b. Automatically Collected Information: We may collect certain information automatically when you use our Services, such as your IP address, device information, and usage information.`}</Text>
            <Text style={styles.description}>{`c. Cookies and Similar Technologies: We may use cookies and similar technologies to collect information about your interactions with our Services.`}</Text>
            <Text style={styles.title}>{`How We Use Your Information`}</Text>
            <Text style={styles.description}>{`a. To Provide and Maintain Our Services: We use the information we collect to provide and maintain our Services, authenticate users, and communicate with you.`}</Text>
            <Text style={styles.description}>{`b. To Improve Our Services: We may use the information we collect to understand how users interact with our Services and to improve our Services.`}</Text>
            <Text style={styles.description}>{`c. For Marketing and Advertising: We may use your information to send you promotional materials and to show you targeted advertisements.`}</Text>
            <Text style={styles.title}>{`How We Share Your Information`}</Text>
            <Text style={styles.description}>{`a. Service Providers: We may share your information with third-party service providers who help us operate our Services.`}</Text>
            <Text style={styles.description}>{`b. Legal Compliance: We may disclose your information if required to do so by law or if we believe that such disclosure is necessary to comply with legal obligations.`}</Text>
            <Text style={styles.description}>{`c. Business Transfers: If we are involved in a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.`}</Text>
            <Text style={styles.title}>{`Your Choices`}</Text>
            <Text style={styles.description}>{`a. Account Information: You may update or delete your account information at any time by logging into your account settings.`}</Text>
            <Text style={styles.description}>{`b. Cookies: You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer.`}</Text>
            <Text style={styles.title}>{`Security`}</Text>
            <Text style={styles.description}>{`We take reasonable measures to protect the security of your personal information.`}</Text>
            <Text style={styles.title}>{`Children's Privacy`}</Text>
            <Text style={styles.description}>{`Our Services are not directed to children under the age of 18, and we do not knowingly collect personal information from children under the age of 18.`}</Text>
            <Text style={styles.title}>{`Changes to This Privacy Policy`}</Text>
            <Text style={styles.description}>{`We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.`}</Text>
            <Text style={styles.title}>{`Contact Us`}</Text>
            <Text style={styles.description}>{`If you have any questions about this Privacy Policy, please contact us at contact@MyBetterBuilder.com.`}</Text>
            <Text style={styles.description}>{`By using our Services, you agree to the collection and use of information in accordance with this Privacy Policy.`}</Text>

        </View>
    );
}

const styles = StyleSheet.create(
    {
        root: {
            backgroundColor: 'white',
            padding: 50
        },
        header: {
            color: "#0B3C49",
            fontSize: 22,
            fontWeight: "700",
            fontFamily: 'Eczar-Regular',
            position: "relative",
            paddingVertical: 10,
            textAlign: "center",
        },
        subHeader: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            fontFamily: 'Eczar-Regular',
            position: "relative",
            paddingVertical: 10,
            textAlign: "center",
        },
        title: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            fontFamily: 'Eczar-Regular',
            position: "relative",
            paddingVertical: 10,
        },
        description: {
            color: "#0B3C49",
            fontSize: 16,
            fontWeight: "500",
            fontFamily: 'Eczar-Regular',
            position: "relative",
            paddingVertical: 10,
        },
    }
)

export default PrivacyPage;
