import './App.css';
import AutoInsuranceTabs from './screens/autoInsurance';
import AutoHomePage from './screens/autoHome';
import TermsPage from './screens/terms';
import PrivacyPage from './screens/privacy';
import { HashRouter, Route, Routes } from "react-router-dom";
import ConfirmScreen from './screens/confirmScreen';

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<AutoHomePage />} />
        <Route path="*" element={<AutoHomePage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/info" element={<AutoInsuranceTabs />} />
        <Route path="/confirm" element={<ConfirmScreen />} />
      </Routes>
    </HashRouter>
  );
}
export default App;
