import { View, Text, StyleSheet, ImageBackground, Button, Image } from 'react-native';
import { isMobile } from '../helpers/deviceUtils';


function TermsPage({ navigation }) {

    return (
        <View style={styles.root}>
            <Text style={styles.header}>{`Terms of Use`}</Text>
            <Text style={styles.subHeader}>{`Effective as of August 1, 2023`}</Text>

            <Text style={styles.description}>{`Welcome to MyBetterBuilder.com! (referred to as “MyBetterBuilder.com”, “we”, “our”, or “us”). We're thrilled to have you join our online community of users who are passionate about home improvement and construction projects. Before you dive into exploring our website(s) and app(s) accessible through various devices like computers, TVs, or mobile phones, please take a moment to review our important terms and conditions (the “Terms of Use”). Your use of our Services constitutes your agreement to abide by these terms, including the Binding Arbitration section outlined in Section 14 below.`}</Text>
            <Text style={styles.description}>{`By accessing or using our Services, you confirm that you are at least 18 years old and legally capable of entering into a contract, and you agree to be bound by these Terms of Use. If you do not agree with any part of these terms or our Privacy Policy (which can be found in the footer of each webpage), please refrain from using our Services.`}</Text>
            <Text style={styles.description}>{`Whether you're a visitor or a member, these Terms of Use govern your access to and use of our Services, establishing a legal agreement between you and MyBetterBuilder.com. These terms outline our rights and obligations and serve to limit our liability to you. We reserve the right to modify, suspend, or terminate your access to our Services at our discretion.`}</Text>
            <Text style={styles.title}>{`1. Eligibility and Registration`}</Text>
            <Text style={styles.description}>{`To access certain features of our Services, you must register for an account and provide accurate and complete information, including a valid email address. By registering, you authorize us to verify your account information as needed. Please keep your account information secure and promptly update it with any changes. You agree not to use false identities or unauthorized usernames and passwords. We may require you to change your username at any time.`}</Text>
            <Text style={styles.title}>{`2. License`}</Text>
            <Text style={styles.description}>{`We grant you a limited, personal, non-exclusive license to use our Services, subject to your compliance with these Terms of Use and any additional terms set forth by us, including our Privacy Policy. This license is for personal use only and does not permit commercial or institutional use. We retain all rights to the Services and any associated content, which may be revoked at our discretion.`}</Text>
            <Text style={styles.title}>{`3. Proprietary Rights`}</Text>
            <Text style={styles.description}>{`The content and intellectual property within our Services are protected by law. You agree not to reproduce, modify, or exploit any content, software, or services provided through our platform.`}</Text>
            <Text style={styles.title}>{`4. Use of the Services`}</Text>
            <Text style={styles.description}>{`You agree to use our Services responsibly and not engage in any activity that may harm or interfere with them or others' use. This includes refraining from transmitting harmful software or engaging in unlawful activities.`}</Text>
            <Text style={styles.title}>{`5. Termination or Suspension of the Services & Modification of the Terms of Use`}</Text>
            <Text style={styles.description}>{`We reserve the right to modify, suspend, or terminate our Services or these Terms of Use at any time without prior notice. Continued use of our Services after any modifications constitutes acceptance of the updated terms.`}</Text>
            <Text style={styles.title}>{`6. Loyalty Program`}</Text>
            <Text style={styles.description}>{`From time to time, we may offer a loyalty program for select applications and user accounts. Participation is optional, and certain eligibility requirements may apply.`}</Text>
            <Text style={styles.title}>{`7. User Submissions`}</Text>
            <Text style={styles.description}>{`You may submit content to our Services, but you are solely responsible for its accuracy and legality. We reserve the right to moderate or remove any user-generated content.`}</Text>
            <Text style={styles.title}>{`8. Feedback`}</Text>
            <Text style={styles.description}>{`We value your feedback and encourage you to share your thoughts with us. Any feedback you provide becomes our property, and we may use it for any purpose.`}</Text>
            <Text style={styles.title}>{`9. Indemnification`}</Text>
            <Text style={styles.description}>{`You agree to indemnify us against any claims arising from your use of our Services or violation of these Terms of Use.`}</Text>
            <Text style={styles.title}>{`10. Disclaimer of Warranty`}</Text>
            <Text style={styles.description}>{`Our Services are provided ""as is,"" and we make no warranties regarding their quality, availability, or suitability for a particular purpose.`}</Text>
            <Text style={styles.title}>{`11. Limitation of Liability`}</Text>
            <Text style={styles.description}>{`We are not liable for any indirect, incidental, or consequential damages arising from your use of our Services. Our aggregate liability will not exceed $50.`}</Text>
            <Text style={styles.title}>{`12. Release`}</Text>
            <Text style={styles.description}>{`By using our Services, you release us from any claims arising from disputes between you and third parties.`}</Text>
            <Text style={styles.title}>{`13. Governing Law and Forum`}</Text>
            <Text style={styles.description}>{`These Terms of Use are governed by the laws of the State of Florida. Any disputes will be resolved through binding arbitration or in a court in Palm Beach County, Florida.`}</Text>
            <Text style={styles.title}>{`14. Binding Arbitration`}</Text>
            <Text style={styles.description}>{`Any disputes not resolved informally will be subject to binding arbitration. You and MyBetterBuilder.com agree to resolve disputes on an individual basis and waive the right to participate in class actions.`}</Text>
            <Text style={styles.title}>{`15. Force Majeure`}</Text>
            <Text style={styles.description}>{`We are not liable for any failure or delay in performance due to events beyond our control, such as natural disasters or government actions.`}</Text>
            <Text style={styles.title}>{`16. Relationship of the Parties`}</Text>
            <Text style={styles.description}>{`You and MyBetterBuilder.com are independent entities, and these Terms of Use do not create any partnership or agency relationship between us.`}</Text>
            <Text style={styles.title}>{`17. Notice; Consent to Electronic Notice`}</Text>
            <Text style={styles.description}>{`You consent to receive notices electronically, and any communications will be deemed received upon transmission.`}</Text>
            <Text style={styles.title}>{`18. Third-Party Links`}</Text>
            <Text style={styles.description}>{`We may provide links to third-party websites, but we are not responsible for their content or activities.`}</Text>
            <Text style={styles.title}>{`19. App Distributors`}</Text>
            <Text style={styles.description}>{`Certain areas of our Services may be accessible through third-party app distributors, and you agree to review their terms of service before using their platforms.`}</Text>
            <Text style={styles.title}>{`20. Miscellaneous`}</Text>
            <Text style={styles.description}>{`If any provision of these Terms of Use is deemed invalid, the remaining provisions will still apply. These Terms of Use constitute the entire agreement between you and MyBetterBuilder.com.`}</Text>
            <Text style={styles.title}>{`21. No Unsolicited Submissions`}</Text>
            <Text style={styles.description}>{`We do not accept unsolicited material, and any submissions made without our request will be disregarded.`}</Text>
            <Text style={styles.title}>{`22. Intellectual Property Infringement`}</Text>
            <Text style={styles.description}>{`If you believe your intellectual property rights have been violated, please contact us at contact@MyBetterBuilder.com.`}</Text>
            <Text style={styles.title}>{`23. Questions`}</Text>
            <Text style={styles.description}>{`If you have any questions about these Terms of Use or our Privacy Policy, please contact us at contact@MyBetterBuilder.com.`}</Text>
            <Text style={styles.description}>{`Thank you for reviewing these terms, and we hope you enjoy using MyBetterBuilder.com!`}</Text>

        </View>
    );
}

const styles = StyleSheet.create(
    {
        root: {
            backgroundColor: 'white',
            padding: 50
        },
        header: {
            color: "#0B3C49",
            fontSize: 22,
            fontWeight: "700",
            fontFamily: 'Eczar-Regular',
            position: "relative",
            paddingVertical: 10,
            textAlign: "center",
        },
        subHeader: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            fontFamily: 'Eczar-Regular',
            position: "relative",
            paddingVertical: 10,
            textAlign: "center",
        },
        title: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            fontFamily: 'Eczar-Regular',
            position: "relative",
            paddingVertical: 10,
        },
        description: {
            color: "#0B3C49",
            fontSize: 16,
            fontWeight: "500",
            fontFamily: 'Eczar-Regular',
            position: "relative",
            paddingVertical: 10,
        },
    }
)
export default TermsPage;
