import { View, Text, StyleSheet, ImageBackground, Button, TextInput } from 'react-native';
import { useState } from 'react';
import Footer from '../components/Footer';
import validator from 'validator';
import { ERROR_MESSAGES } from '../constants/errorMessages';
import { isMobile } from '../helpers/deviceUtils';
import { Image, Pressable } from 'react-native-web';
import { useNavigate } from 'react-router-dom';
import TopTriangle from '../components/TopTriangle';
import AutoInsuranceTabs from './autoInsurance';

const isMobileDevice = isMobile();


function AutoHomePage({ navigation }) {
    const [zipCode, setZipCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isShowInfoView, setIsShowInfoView] = useState(false);
    const styles = isMobileDevice ? mobileStyles : desktopStyles;
    const navigate = useNavigate()

    return (
        <View style={styles.root}>
            <View style={styles.header}>
                <View style={styles.headerContent}>
                    <Image source={require('../assets/header-icon.png')}
                        style={styles.headerIcon} />
                    <Text style={styles.title}>{messages.title}</Text>
                </View>
            </View>

            <View style={styles.contentView}>
                <ImageBackground source={require('../assets/background.png')}
                    style={styles.image} />

                <View style={styles.mainContentView}>

                    <View style={styles.triangle}>
                        <TopTriangle />
                    </View>

                    {!isShowInfoView && <View style={styles.mainContent} >
                        <Text style={styles.description}>{messages.description}</Text>
                        {errorMessage && <Text style={styles.error}>{errorMessage}</Text>}
                        <TextInput style={styles.textInput}
                            placeholder={messages.zipCode}
                            placeholderTextColor={'rgba(25,28,31,0.5)'}
                            value={zipCode}
                            inputMode='numeric'
                            maxLength={5}
                            onChangeText={(newValue) => {
                                setZipCode(newValue);
                            }} />


                        <Pressable style={[styles.buttonView]}
                            onPress={() => {
                                const isValid = validator.isPostalCode(zipCode, "US");
                                if (isValid) {
                                    setErrorMessage("");
                                    setIsShowInfoView(true)
                                } else {
                                    setErrorMessage(ERROR_MESSAGES.ZIP_CODE)
                                }
                            }}>
                            <Text style={styles.buttonContent}>{messages.seeQuotes}</Text>
                        </Pressable>
                    </View>}
                    {isShowInfoView && <View style={styles.mainContent} >
                        <AutoInsuranceTabs zipCode={zipCode} />
                    </View>}
                </View>
            </View>
            <Footer navigation={navigation} style={styles.footer}>

            </Footer>

        </View>
    );
}

const baseStyles = StyleSheet.create(
    {
        root: {
            backgroundColor: '#91E9EC',
        },
        header: {
            height: window.innerHeight * 0.1,
            backgroundColor: 'white',
            justifyContent: "center",
            alignItems: "center",
        },
        headerContent: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
        },
        headerIcon: {
            width: 100,
            height: '90%',
            resizeMode: 'contain',
        },
        title: {
            color: "#0B3C49",
            top: 10,
            fontSize: 35,
            fontFamily: 'Eczar-Bold',
        },
        triangle: {
            position: "relative",
            marginTop: 40,
            width: "100%",
            height: 110,
            alignItems: "center"
        },
        contentView: {
            height: window.innerHeight * 0.9,
            width: "100%",
            alignItems: "center"
        },
        mainContentView: {
            width: '100%',
            height: '100%',
            alignItems: 'center',
            position: 'absolute'
        },
        mainContent: {
            width: 710,
            height: 450,
            position: "relative",
            borderWidth: 3,
            borderColor: "white",
            alignItems: "center",
            justifyContent: "center",
            padding: 40
        },
        image: {
            width: "100%",
            height: '100%',
            resizeMode: 'cover',
        },
        description: {
            color: "white",
            fontFamily: 'Eczar-Bold',
            textAlign: 'center',
        },
        error: {
            color: "red",
            fontWeight: "400",
            fontFamily: 'Eczar-Regular',
            lineHeight: 24
        },
        inputView: {
            flexDirection: "row",
            marginTop: 40
        },
        textInput: {
            backgroundColor: "white",
            borderRadius: 10,
            fontWeight: 400,
            fontFamily: 'Eczar-Regular',
            height: 60,
            marginTop: 30,
            padding: 15,
        },
        footer: {
            position: "absolute",
            marginTop: window.innerHeight * 0.94,
            flexDirection: "row",
            justifyContent: "center",
            width: "100%"
        },
        buttonView: {
            backgroundColor: "#943E26",
            position: "relative",
            borderRadius: 10,
            paddingVertical: 5,
            paddingHorizontal: 15,
            fontWeight: "500",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 30,
            width: 120,
            height: 60,
        },
        buttonContent: {
            color: "#FFFFFF",
            fontSize: 16,
            lineHeight: "100%",
            fontFamily: 'Eczar-Bold',
            padding: 14,
        },
    }
)

const desktopStyles = StyleSheet.create(
    {
        ...baseStyles,
        header: {
            ...baseStyles.header,
            width: '100%',
        },
        contentView: {
            ...baseStyles.contentView,
            justifyContent: "center"
        },
        description: {
            ...baseStyles.description,
            fontSize: 64,
            lineHeight: 66
        },
        error: {
            ...baseStyles.error,
            fontSize: 24,
        },
        textInput: {
            ...baseStyles.textInput,
            fontSize: 12,
            width: 220
        },
    }
)


const mobileStyles = StyleSheet.create(
    {
        ...baseStyles,
        root: {
            ...baseStyles.root,
            height: "100%",
            width: '100%',
        },
        header: {
            ...baseStyles.header,
            width: window.innerWidth,
        },
        headerContent: {
            marginLeft: "10%",
            flexDirection: "row",
            alignItems: "center"
        },
        contentView: {
            ...baseStyles.contentView,
            justifyContent: "flex-start"
        },
        mainContent: {
            marginLeft: "10%",
            width: "80%",
            position: "absolute",
            marginTop: 65,
            paddingRight: 20
        },
        description: {
            ...baseStyles.description,
            fontSize: 30,
            lineHeight: 38
        },
        error: {
            ...baseStyles.error,
            fontSize: 20,
        },
        textInput: {
            ...baseStyles.textInput,
            fontSize: 10,
            width: "60%"
        },
    }
)

const messages = {
    title: "My Better Builder",
    description: "Find a partner to get the job done!",
    zipCode: "ENTER YOUR ZIP CODE",
    seeQuotes: "EXPLORE",
}

export default AutoHomePage;
